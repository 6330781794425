import React from 'react';

const Blog = () => {
  const redirectToMediumBlog = () => {
    window.open('https://whoisshuvam.medium.com/', '_blank');
  };

  const handleBlogLinkClick = (event) => {
    event.preventDefault();
    redirectToMediumBlog();
  };

  return (
    <div>
      <h1>Blog Section</h1>
      <a href="#blog" className="go-to-blog-link" onClick={handleBlogLinkClick}>
        Go to Blog
      </a>
    </div>
  );
};

export default Blog;
