import React, { useEffect } from 'react';
import './App.css';
import Sidebar from './components/sidebar/Sidebar';
import Home from './components/home/Home';
import About from './components/about/About';
import Services from './components/services/Services';
import Resume from './components/resume/Resume';
import Portfolio from './components/portfolio/Portfolio';
import Pricing from './components/pricing/Pricing';
import Testimonials from './components/testimonials/Testimonials';
import Blog from './components/blog/Blog';
import Contact from './components/contact/Contact';

const App = () => {
  const preventViewSource = (event) => {
    if (event.ctrlKey && event.key === 'u') { // Check if the key is 'u'
      event.preventDefault();
      window.location.href = './giphy.gif';
    }
  };

  // Attach the event handler to the 'keydown' event when the component mounts
  useEffect(() => {
    document.addEventListener('keydown', preventViewSource);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', preventViewSource);
    };
  }, []);

  return (
    <>
      <Sidebar />
      <main className='main'>
        <Home />
        <About />
        <Services />
        <Resume />
        <Portfolio />
        <Pricing />
        <Testimonials />
        <Blog />
        <Contact />
      </main>
    </>
  );
};

export default App;
