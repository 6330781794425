import Work1 from "../../assets/work-1.svg";
import Work2 from "../../assets/work-2.svg";
import Work3 from "../../assets/work-3.svg";
import Work4 from "../../assets/work-4.svg";
import Work5 from "../../assets/work-5.jpg";
import Work6 from "../../assets/work-6.svg";
import Work7 from "../../assets/work-7.jpg";
import Swag1 from "../../assets/swag-1.jpg";
import Swag2 from "../../assets/swag-2.jpg";
import Swag4 from "../../assets/swag-4.jpg";
import Swag9 from "../../assets/swag-9.jpg";



const Menu = [
  {
    id: 1,
    image: Work1,
    title: "test",
    category: "Findings",
  },
{
    id: 2,
    image: Work2,
    title: "Owasp Kathmandu 2023",
    category: "Conferences",
  },
  {
    id: 3,
    image: Work3,
    title: "gotld",
    category: "Development",
  },
  {
    id: 4,
    image: Work4,
    title: "test2",
    category: "CTF Walkthrough",
  },
  {
    id: 5,
    image: Work5,
    title: "Eset",
    category: "Swags",
  },
  {
    id: 6,
    image: Work6,
    title: "test",
    category: "Research",
  },
  {
    id: 7,
    image: Work7,
    title: "Sony",
    category: "Swags",
  },
  {
    id: 8,
    image: Swag1,
    title: "Belastingdienst",
    category: "Swags",
  },
  {
    id: 9,
    image: Swag2,
    title: "HacktoberFest",
    category: "Swags",
  },
  {
    id: 10,  
    image: Swag4,
    title: "Google VRP",
    category: "Swags",
  },
  {
    id: 11,
    image: Swag9,
    title: "Hackerone",
    category: "Swags",
  },
];

export default Menu;
