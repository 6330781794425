import React from 'react';

const AboutBox = () => {
  return (
    <div className="about__boxes grid">

      <div className="about__box">
        <i className="fa-solid fa-bug-slash fa-2x"></i>
        <div>
          <h3 className="about__title">400+</h3>
          <span className="about__subtitle">Vulnerabilities Fixed</span>
        </div>
      </div>

      <div className="about__box">
        <i className="fa-solid fa-link fa-2x"></i>
        <div>
          <h3 className="about__title">5</h3>
          <span className="about__subtitle">CVES Received</span>
        </div>
      </div>

      <div className="about__box">
        <i className="fa-regular fa-handshake fa-2x"></i>
        <div>
          <h3 className="about__title">45+</h3>
          <span className="about__subtitle">Companies Secured</span>
        </div>
      </div>

      <div className="about__box">
        <i className="fa-solid fa-calendar-days fa-2x"></i>
        <div>
          <h3 className="about__title">3</h3>
          <span className="about__subtitle">Years of Experience</span>
        </div>
      </div>

    </div>
  );
};

export default AboutBox;
