import React from 'react';
import './about.css';
import Image from '../../assets/avatar-2.svg';
import AboutBox from './AboutBox';

const About = () => {
  return (
<section className="about container section" id='about'>
  <h2 className="section__title">About Me</h2>

  <div className="about__container grid"></div>
  <img src={Image} alt="" className="about__img" />

  <div className="about__data grid">

    <div className="about__info">
      <p className="about__description"> I'm Suvam Adhikari, a passionate security engineer. With a strong background in cybersecurity and a relentless curiosity for exploring new security challenges, I strive to make the digital world a safer place.</p>
      <a href="" className="btn">Download CV</a>
    </div>

    <div className="about__skills grid">
      <div className="skills__data">
        <div className="skills__titles">
          <h3 className="skills_name">Web Security</h3>
          <span className="skills__number">93%</span>
        </div>

        <div className="skills__bar">
          <span className="skills__percentage web"></span>
        </div>
      </div>

      <div className="skills__data">
        <div className="skills__titles">
          <h3 className="skills_name">Network Security</h3>
          <span className="skills__number">82%</span>
        </div>

        <div className="skills__bar">
          <span className="skills__percentage network"></span>
        </div>
      </div>

      <div className="skills__data">
        <div className="skills__titles">
          <h3 className="skills_name">Windows Security</h3>
          <span className="skills__number">62%</span>
        </div>

        <div className="skills__bar">
          <span className="skills__percentage windows"></span>
        </div>
      </div>

      <div className="skills__data">
  <div className="skills__titles">
    <h3 className="skills_name">Development</h3>
    <span className="skills__number">72%</span>
  </div>

  <div className="skills__bar">
    <span className="skills__percentage full"></span>
  </div>
</div>

    </div>
  </div>
  <AboutBox />
</section>
    )
}

export default About