import React, { useState } from 'react';
import './portfolio.css';
import Menu from './Menu';

const Portfolio = () => {
  const [items, setItems] = useState(Menu);
  const [lightboxImage, setLightboxImage] = useState(null);

  const filterItem = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category === categoryItem;
    });
    setItems(updatedItems);
  };

  const openLightbox = (imageSrc) => {
    setLightboxImage(imageSrc);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  return (
    <section className="work container" id="work">
      <h2 className="section__title">Recent Works</h2>

      <div className="work__filters">
        <span className="work__item" onClick={() => setItems(Menu)}>Everything</span>
        <span className="work__item" onClick={() => filterItem("Findings")}>Findings</span>
        <span className="work__item" onClick={() => filterItem("Conferences")}>Conferences</span>
        <span className="work__item" onClick={() => filterItem("Development")}>Development</span>
        <span className="work__item" onClick={() => filterItem("CTF Walkthrough")}>CTF Walkthrough</span>
        <span className="work__item" onClick={() => filterItem("Research")}>Research</span>
        <span className="work__item" onClick={() => filterItem("Swags")}>Swags</span>
      </div>

      <div className={`work__container grid ${items[0] && items[0].category === "hof" ? "hall-of-fame-grid" : ""}`}>
        {items.slice(0, 6).map((elem) => { // Displaying the first 6 items
          const { id, image, title, category } = elem;
          return (
                  <div className="work__card" key={id}>
              <div className="work__thumbnail">
                <img
                  src={image}
                  alt=""
                  className="work__img"
                  onClick={() => openLightbox(image)}
                />
                <div className="work__mask"></div>
              </div>
              <span className="work__category">{category}</span>
              <h3 className="work__title">{title}</h3>
              <a href="#" className="work__button">
                <i className="icon-link work__button-icon"></i>
              </a>
            </div>
          );
        })}
      </div>

      {lightboxImage && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <div className="lightbox-content">
            <img src={lightboxImage} alt="Full Size" className="lightbox-img" />
            <button className="lightbox-close" onClick={closeLightbox}>
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Portfolio;
