const Data = [
    {
      id: 1,
      category: "education",
      icon: "icon-graduation",
      year: "2023 - present",
      title: "High School Diploma @ Loyalist College, Belleville Canada",
      desc: "Currently pursuing diploma in Computer System Technician and Networking course",
    },
    {
      id: 2,
      category: "education",
      icon: "icon-graduation",
      year: "2019 - 2021",
      title: "High School Diploma @ SSCSS, Pokhara Nepal",
      desc: "Achieved a High School Diploma with a comprehensive curriculum encompassing subjects such as Physics, Chemistry, and Mathematics.",
    },
    {
      id: 3,
      category: "education",
      icon: "icon-graduation",
      year: "2008 - 2018",
      title: "Secondary Education Examination",
      desc: "Completed SEE examination from Saint Francis School, Pokhara Nepal.",
    },
    {
      id: 4,
      category: "experience",
      icon: "icon-briefcase",
      year: "2023 - present",
      title: "QA Analyst @ Black Tech Pokhara",
      desc: "As a QA Analyst at BlackTech, I've conducted rigorous testing, identified critical issues, and improved software quality.",
    },
    {
      id: 5,
      category: "experience",
      icon: "icon-briefcase",
      year: "2021 - 2023",
      title: "Security Researcher",
      desc: "110+ vulnerabilities discovered, responsible disclosures, and secure collaboration with 40+ companies on HackerOne.",
    },
    {
      id: 6,
      category: "experience",
      icon: "icon-briefcase",
      year: "2021 - 2022",
      title: "Frontend Developer @ Majheri Manzil Pokhara, Nepal",
      desc: "Responsible for crafting a dependable frontend and backend system to streamline place reservations and food orders for guests.",
    },
  ];

export default Data