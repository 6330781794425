import React from 'react';
import emailjs from 'emailjs-com';
import "./contact.css";

const Contact = () => {
  const sendEmail = (event) => {
    event.preventDefault();

   // Replace with your Public Key, Service ID, and Template ID
    const publicKey = '_kPmR4xHjstVTY3eq';
    const serviceID = 'service_l21cq6q';
    const templateID = 'template_bc0h15c';

    const formData = new FormData(event.target);

    const templateParams = {};
    formData.forEach((value, key) => {
      templateParams[key] = sanitizeInput(value);
    });

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
      }, (error) => {
        console.log('Failed to send email:', error.text);
      });
  };

  const sanitizeInput = (input) => {
    return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  return (
    <section className="contact container section" id='contact'>
      <h2 className="section__title">Get in Touch</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Let's talk about everything!</h3>
          <p className="contact__details">Don't like forms? Send me an <a href="mailto:shuvam321@wearehackerone.com">email.</a></p>        </div>

        <form onSubmit={sendEmail} className="contact__form">
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input type='text' name="from_name" className="contact__form-input" placeholder='Insert your name' />
            </div>

            <div className="contact__form-div">
              <input type="email" name="email_id" className="contact__form-input" placeholder='Insert your email' />
            </div>
          </div>

          <div className="contact__form-div">
            <input type="text" name="subject" className="contact__form-input" placeholder='Insert your subject' />
          </div>

          <div className="contact__form-div">
            <textarea name="message" cols="30" rows="10" className="contact__form-input" placeholder='Write your message' />
          </div>

          <button type="submit" className="btn">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
