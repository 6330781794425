import React, { useState, useEffect } from 'react';
import './sidebar.css';
import Logo from "../../assets/logo.svg";

const Sidebar = () => {
  const [toggle, setToggle] = useState(true);

  const handleResize = () => {
    // Check if the viewport width is less than a certain threshold
    const shouldShowSidebar = window.innerWidth <= 1024; // Adjust the threshold as needed
    setToggle(shouldShowSidebar);
  };

  useEffect(() => {
    // Call handleResize initially
    handleResize();
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {toggle && (
        <aside className="aside show-menu">
 <a href="#home" className="nav__logo">
  <img src={Logo} alt="" />
  </a>

  <nav className="nav">
    <div className="nav__menu">
      <ul className="nav__list">
        <li className="nav__item">
          <a href="#home" className="nav__link">
            <i className="icon-home"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#about" className="nav__link">
          <i className="icon-user-following"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#services" className="nav__link">
          <i className="icon-briefcase"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#resume" className="nav__link">
          <i className="icon-graduation"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#work" className="nav__link">
          <i className="icon-layers"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#blog" className="nav__link">
          <i className="icon-note"></i>
          </a>
        </li>

        <li className="nav__item">
          <a href="#contact" className="nav__link">
          <i className="icon-bubble"></i>
          </a>
        </li>


      </ul>
    </div>
  </nav>

<div className="nav__footer">
<span className="copyright">&copy; 2023 - 2024.</span>
</div>
        </aside>
      )}

      <div className={toggle ? "nav__toggle nav__toggle-open" : "nav__toggle"} onClick={() => setToggle(!toggle)}>
        <i className="icon-menu"></i>
      </div>
    </>
  );
};

export default Sidebar;
